var packingParagraphs = {

    elements: {
        button: '.js__packing-paragraphs'
    },

    init: function(){
        let listElements = document.querySelectorAll(this.elements.button);
        if(listElements.length > 0) {
            for(let i=0; i<listElements.length; i++){
                this.oneElementInit(listElements[i]);
            }
        }
    },

    oneElementInit: function(element){
        const containerID = makeRandomString(7);
        let button = document.createElement("span");
        button.innerHTML = 'více';
        button.className = 'hide';
        button.setAttribute('data-text-more', 'více');
        button.setAttribute('data-text-less', 'méně');
        button.setAttribute('data-is-more', '0');
        button.setAttribute('data-container-id', containerID);
        let divContainer = document.createElement("div");
        element.setAttribute('id', containerID);
        addClass(element, 'packing-paragraphs');
        addClass(element, 'hide');
        addClass(divContainer, 'packing-paragraphs-container');
        insertAfter(element, divContainer);
        divContainer.appendChild(element);
        divContainer.appendChild(button);
        button.addEventListener('click', function(){
            const containerID = this.getAttribute('data-container-id');
            let element = document.getElementById(containerID);
            if(element){
                if(this.getAttribute('data-is-more') === '0'){
                    removeClass(element, 'hide');
                    removeClass(this, 'hide');
                    this.setAttribute('data-is-more','1');
                    this.innerHTML = this.getAttribute('data-text-less');
                }else{
                    addClass(element, 'hide');
                    addClass(this, 'hide');
                    this.setAttribute('data-is-more','0');
                    this.innerHTML = this.getAttribute('data-text-more');
                }
            }
        });
    }

};