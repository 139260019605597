var menu = {

    menuContainerID: '',

    elements: {
        button: '.js__menu-button-mobile',
        dropDown: '.js__menu-have-sub-items',
        menuContainer: '.js_menu-container',
        menuBackButton: '.js__menu-mobile-back'
    },

    dataAttrs: {
        menuContainerID: 'data-menu-container-id',
        groupID: 'data-group'
    },

    className: {
        openMenuContainer: 'open-mobile-menu',
        dropDownOpen: 'drop-down-is-open',
        dropDownJustOpen: 'drop-down-just-open',
        show: 'menu-show',
        parentShow: 'menu-parent-show'
    },

    initOpemClose: function(){
        var button = document.querySelector(this.elements.button);
        if(button){
            var _self = this;
            this.menuContainerID = button.getAttribute(this.dataAttrs.menuContainerID)
            button.addEventListener('click', function(e){
                e.preventDefault();
                _self.openCloseMenu();
            });
            button.addEventListener('touchstart', function(e){
                e.preventDefault();
                _self.openCloseMenu();
            });
        }
    },

    getContainer: function(){
        if(this.menuContainerID.length > 0){
            return document.getElementById(this.menuContainerID);
        }
        return false;
    },

    getMenu: function(){
        var container = this.getContainer();
        if(container){
            return container.querySelector(this.elements.menuContainer);
        }
        return false;
    },

    openCloseMenu: function(){
        var container = this.getContainer();
        if(container){
            var menu = this.getMenu();
            if(menu){
                if(hasClass(container, this.className.openMenuContainer)){
                    removeClass(container, this.className.openMenuContainer);
                    this.removeClassFromAll(this.className.show);
                    this.removeClassFromAll(this.className.parentShow);
                }else{
                    this.showDirectChildren(menu);
                    addClass(container, this.className.openMenuContainer);
                }
            }
        }
    },

    initDropDownClickTouch: function(){
        var _self = this;
        var arrForInit = [this.elements.dropDown, this.elements.menuBackButton];
        for(var x = 0; x < arrForInit.length; x++){
            var list = document.querySelectorAll(arrForInit[x]);
            if(list.length > 0){
                for(var i = 0; i < list.length; i++){
                    _self.initItem(list[i]);
                }
            }
        }
    },

    initItem: function(el){
        var _self = this;
        el.addEventListener('click', function(e){
            e.preventDefault();
            if(_self.isMobileVersion() === false){
                window.location = el.href
                return true;
            }
            return _self.openDropDown(this);
        });
        el.addEventListener('touchstart', function(e){
            e.preventDefault();
             _self.openDropDown(this);
        });
    },

    // openDropDownResetJustOpen: function(){
    //     var _self = this;
    //     var justOpenList = document.querySelectorAll('.' + this.className.dropDownJustOpen);
    //     if(justOpenList.length > 0){
    //         for(var i = 0; i < justOpenList.length; i++){
    //             removeClass(justOpenList[i], _self.className.dropDownJustOpen);
    //         }
    //     }
    // },

    openDropDown: function(el){
        var groupContainer = document.getElementById(el.getAttribute(this.dataAttrs.groupID));
        if(groupContainer){
            this.removeClassFromAll(this.className.show);
            this.removeClassFromAll(this.className.parentShow);
            this.showDirectChildren(groupContainer);
            this.showParents(groupContainer);
            addClass(groupContainer, this.className.show);
        }
        return true;
    },

    removeClassFromAll: function(className){
        var container = this.getContainer();
        if(container){
            var list = container.querySelectorAll('.' + className);
            if(list.length > 0){
                for(var i = 0; i < list.length; i++){
                    removeClass(list[i], className);
                }
            }
        }
    },

    showParents: function(el){
        var className = this.elements.menuContainer;
        className = className.substring(1);
        if(hasClass(el, className)){
            return;
        }
        this.showParents_showing(el.parentNode, className);
    },

    showParents_showing: function(parent, stopClassName){
        if(hasClass(parent, stopClassName)){
            return;
        }
        addClass(parent, this.className.parentShow);
        this.showParents_showing(parent.parentNode, stopClassName);
    },

    openDropDownOrRedirect: function(el){
        var groupContainer = document.getElementById(el.getAttribute(this.dataAttrs.groupID));
        if(groupContainer){
            if(hasClass(groupContainer, this.className.dropDownOpen)){
                window.location = el.href;
                return true;
            }else{
                addClass(groupContainer, this.className.dropDownOpen);
                return false;
            }
        }
        return true;
    },

    isMobileVersion: function(){
        var button = document.querySelector(this.elements.button);
        if(!button){
            return false;
        }
        return window.getComputedStyle(button, null).display !== 'none';
    },

    showDirectChildren: function(parent){
        var _self = this;
        if(parent.children.length > 0){
            for(var i = 0; i < parent.children.length; i++){
                addClass(parent.children[i], _self.className.show);
            }
        }
    }

};