var formListOfUrl = {

    config: {
        prefixForClassNameOfGroup: 'listofurlgroup-'
    },

    elements: {
        item: '.js__form-list-of-url',
        hiddenElement: '.js__form-list-of-url--hidden'
    },

    dataAttrs: {
        formID: 'data-form-id',
        formBaseUrl: 'data-form-base-url',
        itemUrl: 'data-form-item-url',
        itemHidden: 'data-form-hidden-element',
        position: 'data-form-item-position',
        selectedContainer: 'data-selected-container'
    },

    init: function(){
        var _self = this;
        var listItems = document.querySelectorAll(this.elements.item);
        if(listItems.length > 0){
            for(var i = 0; i < listItems.length; i++){
                listItems[i].className = listItems[i].className + ' ' + _self.getGroupClassName(listItems[i].getAttribute(_self.dataAttrs.itemHidden));
                listItems[i].addEventListener('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
                    _self.click(this);
                    return false;
                });
            }
        }
    },

    getGroupClassName: function(groupName){
        return this.config.prefixForClassNameOfGroup + groupName;
    },

    removeActiveFromGroup: function(groupName){
        var listItems = document.querySelectorAll('.' + groupName);
        if(listItems.length > 0){
            for(var i = 0; i < listItems.length; i++){
                removeClass(listItems[i], 'active')
            }
        }
    },

    click: function(element){
        var hiddenElement = document.getElementById(element.getAttribute(this.dataAttrs.itemHidden));
        if(hiddenElement){
            this.removeActiveFromGroup(this.getGroupClassName(element.getAttribute(this.dataAttrs.itemHidden)));
            addClass(element, 'active');
            hiddenElement.value = element.getAttribute(this.dataAttrs.itemUrl);
            this.setFormAction();
            var selectedContainer = document.getElementById(element.getAttribute(this.dataAttrs.selectedContainer));
            if(selectedContainer){
                selectedContainer.innerHTML = element.innerHTML;
            }
        }
    },

    setFormAction: function(){
        var listElements = document.querySelectorAll(this.elements.hiddenElement);
        if(listElements.length > 0){
            var listUrl = [];
            var baseUrl = '';
            var formID = '';
            for(var i = 0; i < listElements.length; i++){
                formID = listElements[i].getAttribute(this.dataAttrs.formID);
                baseUrl = listElements[i].getAttribute(this.dataAttrs.formBaseUrl);
                itemUrl = listElements[i].value;
                if(itemUrl){
                    listUrl[parseInt(listElements[i].getAttribute(this.dataAttrs.position))] = itemUrl;
                }
            }
            var formElement = document.getElementById(formID);
            if(formElement){
                var finishListUrl = [];
                for(var y = 0; y < listUrl.length; y++){
                    if(listUrl[y]){
                        finishListUrl.push(listUrl[y]);
                    }
                }
                var finalUrl = finishListUrl.join('/');
                if(finalUrl){
                    baseUrl = baseUrl + '/' + finalUrl;
                }
                formElement.setAttribute('action', baseUrl);
            }
        }
    }

};