var cookieBar = {

    debug: false,

    data: {
        cookieName: 'cookiegdpconfirm',
        cookieExpiration: 365,
        cookieValue: 'ok'
    },

    elements: {
        container: 'js__cookie-bar'
    },

    init: function(){
        var existCookie = (this.debug?false:readCookie(this.data.cookieName));
        if(!existCookie){
            var container = document.getElementById(this.elements.container);
            if(container){
                container.style.display = 'block';
                var button = container.getElementsByTagName('button');
                if(button && button.length > 0){
                    button[0].addEventListener('click', function(){
                        document.getElementById(cookieBar.elements.container).style.display = 'none';
                    });
                }
                createCookie(this.data.cookieName, this.data.cookieValue, this.data.cookieExpiration);
            }
        }
    }

};