var formClickProtection = {

    dataAttrs: {
        elementID: 'data-jsclickprotection-el',
        value: 'data-jsclickprotection-value'
    },

    elements: {
        button: '.js__form-javascript-click-protection'
    },

    init: function(){
        var _self = this;
        var listItems = document.querySelectorAll(this.elements.button);
        if(listItems.length > 0){
            for(var i = 0; i < listItems.length; i++){
                _self.initItem(listItems[i]);
            }
        }
    },

    initItem: function(button){
        var _self = this;
        button.addEventListener('click', function(){
            var element = document.getElementById(button.getAttribute(_self.dataAttrs.elementID));
            if(element){
                element.value = button.getAttribute(_self.dataAttrs.value);
            }
        });
    }

};