var galleryShowSpecificNumberImages = {

    elements: {
        items: '.js__gallery-show-specific-number-images'
    },

    dataAttrs: {
        number: 'data-show-only-number',
        childClassName: 'data-item-class-name',
        buttonTextMore: 'data-button-text-more',
        buttonTextLess: 'data-button-text-less',
        galleryID: 'data-gallery-id'
    },

    className: {
        open: 'open-by-click',
        showHideButton: 'gallery-list__show-hide-button',
        showHideContainer: 'gallery-list__show-hide-container',
        hideShowClassName: 'js__gallery-show-specific-number-images__item-show-hide',
        hideClassName: 'hide',
        showHideButtonShow: 'show'
    },

    init: function(){
        var _self = this;
        var listItems = document.querySelectorAll(this.elements.items);
        if(listItems.length > 0){
            for(var i = 0; i < listItems.length; i++){
                _self.initItem(listItems[i]);
            }
        }
    },

    initItem: function(el){
        var _self = this;
        var number = parseInt(el.getAttribute(this.dataAttrs.number));
        if(number < 1){
            return;
        }
        var galleryID = makeRandomString(6);
        el.setAttribute('id', galleryID);
        var itemChilds = el.querySelectorAll('.' + el.getAttribute(this.dataAttrs.childClassName));
        if(itemChilds.length>0){
            var isSomeItemsHidden = false;
            for(var i = 0; i < itemChilds.length; i++){
                if(i >= number){
                    isSomeItemsHidden = true;
                    addClass(itemChilds[i], _self.className.hideShowClassName);
                    addClass(itemChilds[i], _self.className.hideClassName);
                }
            }
            if(isSomeItemsHidden){
                _self.appendShowHideButton(el);
            }
        }
    },

    appendShowHideButton: function(el){
        var button = document.createElement('span');
        button.className = this.className.showHideButton;
        button.innerHTML = el.getAttribute(this.dataAttrs.buttonTextMore);
        button.setAttribute(this.dataAttrs.buttonTextMore, el.getAttribute(this.dataAttrs.buttonTextMore));
        button.setAttribute(this.dataAttrs.buttonTextLess, el.getAttribute(this.dataAttrs.buttonTextLess));
        button.setAttribute(this.dataAttrs.galleryID, el.getAttribute('id'));
        button.addEventListener('click', function(){
            galleryShowSpecificNumberImages.openClose(this);
        });
        var buttonContainer = document.createElement('div');
        buttonContainer.className = this.className.showHideContainer;
        buttonContainer.append(button);
        insertAfter(el, buttonContainer);
    },

    openClose: function(button){
        var galleryListElement = document.getElementById(button.getAttribute(this.dataAttrs.galleryID));
        if(galleryListElement){
            if(hasClass(button, this.className.showHideButtonShow)){
                this.showHideGalleryItems(galleryListElement, false)
                removeClass(button, this.className.showHideButtonShow);
                button.innerHTML = button.getAttribute(this.dataAttrs.buttonTextMore);
            }else{
                this.showHideGalleryItems(galleryListElement)
                addClass(button, this.className.showHideButtonShow);
                button.innerHTML = button.getAttribute(this.dataAttrs.buttonTextLess);
            }
        }
    },

    showHideGalleryItems: function(galleryListElement, show = true){
        var _self = this;
        var itemChilds = galleryListElement.querySelectorAll('.' + _self.className.hideShowClassName);
        if(itemChilds.length>0){
            for(var i = 0; i < itemChilds.length; i++){
                if(show){
                    removeClass(itemChilds[i], _self.className.hideClassName);
                }else{
                    addClass(itemChilds[i], _self.className.hideClassName);
                }
            }
        }
    }

};