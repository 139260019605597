var formDropDown = {

    elements: {
        items: '.js__form-drop-down-item'
    },

    dataAttrs: {
        group: 'data-drop-down-group',
        label: 'data-label',
        showAllText: 'data-show-all-text'
    },

    init: function(){
        var _self = this;
        var listItems = document.querySelectorAll(this.elements.items);
        if(listItems.length > 0){
            for(var i = 0; i < listItems.length; i++){
                _self.initItem(listItems[i]);
            }
        }
    },

    initItem: function(el){
        var _self = this;
        el.addEventListener('click', function(){
            _self.refresh(el.getAttribute(_self.dataAttrs.group));
        });
    },

    refresh: function(groupName){
        var container = document.getElementById(groupName);
        if(container) {
            var arrSelected = [];
            var listItems = document.querySelectorAll('.' + groupName);
            if (listItems.length > 0) {
                for (var i = 0; i < listItems.length; i++) {
                    if (listItems[i].checked) {
                        arrSelected.push(listItems[i].getAttribute(this.dataAttrs.label));
                    }
                }
            }
            if(arrSelected.length>0){
                container.innerHTML = arrSelected.join(', ');
            }else{
                container.innerHTML = container.getAttribute(this.dataAttrs.showAllText);
            }
        }
    }

};