var productListInContentAskForDemand = {

    elements: {
        items: '.js__product-list-in-content--ask-for-demand',
        button: '.js__form-builder-modal-window'
    },

    init: function(){
        let listElements = document.querySelectorAll(this.elements.items);
        if(listElements.length > 0) {
            for(let i=0; i<listElements.length; i++){
                this.oneElementInit(listElements[i]);
            }
        }
    },

    oneElementInit: function(element){
        let button = element.querySelector(this.elements.button);
        if(button){
            let h2 = element.getElementsByTagName('h2');
            if(h2.length > 0){
                button.setAttribute('data-source-url', button.getAttribute('data-source-url') + ' | ' + h2[0].innerHTML.replace(/(<([^>]+)>)/gi, ""));
            }
        }
    }

};