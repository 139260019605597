var formRangeSlider = {

    elements: {
        items: '.js__slider'
    },

    dataAttrs: {
        idInputFrom: 'data-input-from',
        idInputTo: 'data-input-to',
        min: 'data-value-min',
        max: 'data-value-max',
        step: 'data-step',
        isIntType: 'data-is-int-type'
    },

    init: function(){
        var _self = this;
        var listItems = document.querySelectorAll(this.elements.items);
        if(listItems.length > 0){
            for(var i = 0; i < listItems.length; i++){
                _self.initItem(listItems[i]);
            }
        }
    },

    initItem: function(element){
        var _self = this;
        var isIntType = this.isIntType(element);
        var min = this.getCorrectValue(element.getAttribute(_self.dataAttrs.min), isIntType);
        var max = this.getCorrectValue(element.getAttribute(_self.dataAttrs.max), isIntType);
        var start = this.getStartEndValue(element,min);
        var end = this.getStartEndValue(element,max, false);
        var option = {
            start: [start, end],
            connect: true,
            range: {
                'min': min,
                'max': max
            }
        };
        var step = this.getCorrectValue(element.getAttribute(_self.dataAttrs.step), isIntType);
        if(step !== 0){
            option['step'] = this.getCorrectValue(step, isIntType);
        }
        noUiSlider.create(element, option);
        element.noUiSlider.on('update', function (values, handle) {
            //handle == 0 => from
            //handle == 1 => to
            var input = document.getElementById(element.getAttribute(handle===0?_self.dataAttrs.idInputFrom:_self.dataAttrs.idInputTo));
            if(input){
                input.value = _self.getCorrectValue(values[handle], formRangeSlider.isIntType(element));
            }
        });
        this.inputChange(element);
        this.inputChange(element, false);
    },

    getStartEndValue: function(element, defaultValue, isFrom = true){
        var input = document.getElementById(element.getAttribute(isFrom?this.dataAttrs.idInputFrom:this.dataAttrs.idInputTo));
        if(input){
            return input.value;
        }
        return defaultValue;
    },

    inputChange: function(element, isFrom = true){
        var input = document.getElementById(element.getAttribute(isFrom?this.dataAttrs.idInputFrom:this.dataAttrs.idInputTo));
        if(input){
            input.addEventListener('change', function () {
                element.noUiSlider.set(isFrom?[this.value, null]:[null, this.value]);
            });
        }
    },

    getCorrectValue: function(value, isIntType = true){
        return isIntType?parseInt(value):parseFloat(value);
    },

    isIntType: function(element){
        return element.getAttribute(this.dataAttrs.isIntType) === '1';
    }

}