var formBuilder = {

    elements: {
        initButtons: '.js__form-builder-modal-window',
        form: '.js__form-builder-ajax-form'
    },

    dataAttrs: {
        formID: 'data-form-id',
        headline: 'data-headline',
        sourceUrl: 'data-source-url'
    },

    className: {
        errorContainer: 'error-container',
        errorMessage: 'error-message',
        errorForm: 'error-form-message'
    },

    url: '/formbuilder/ajaxform',

    init: function(){
        var _self = this;
        var listItems = document.querySelectorAll(this.elements.initButtons);
        if(listItems.length > 0){
            for(var i = 0; i < listItems.length; i++){
                _self.initItem(listItems[i]);
            }
        }
    },

    removeAllErrors: function(form){
        var _self = this;
        var allErrorContainers = form.querySelectorAll('.' + this.className.errorContainer);
        if(allErrorContainers.length > 0){
            for(var i = 0; i < allErrorContainers.length; i++){
                removeClass(allErrorContainers[i], _self.className.errorContainer);
                var errorMessage = allErrorContainers[i].querySelector('.' + _self.className.errorMessage);
                if(errorMessage){
                    errorMessage.remove();
                }
            }
        }
        var formError = form.querySelector('.' + this.className.errorForm);
        if(formError){
            formError.remove();
        }
    },

    createErrorMessage: function(message){
        var el = document.createElement('div');
        el.className = this.className.errorMessage;
        el.innerHTML = message;
        return el;
    },

    initItem: function(el){
        var _self = this;
        el.addEventListener('click', function(e){
            e.preventDefault();
            modalWindow.showWithLoading();
            element = this;
            aja()
                .method('post')
                .url(formBuilder.url)
                .data({
                    'ajax': 1,
                    'fid': parseInt(element.getAttribute(_self.dataAttrs.formID))
                })
                .into('#' + modalWindow.getContentID())
                .on('success', function () {
                    var content = modalWindow.getContentElement();
                    if(content){
                        var form = content.querySelector(_self.elements.form);
                        if(form){
                            form.setAttribute(_self.dataAttrs.sourceUrl, element.getAttribute(_self.dataAttrs.sourceUrl));
                            modalWindow.changeHeadline(form.getAttribute(_self.dataAttrs.headline));
                            form.addEventListener('submit', function(e){
                                e.preventDefault();
                                var _thisForm = this;
                                var formData = {};
                                var formElements = this.querySelectorAll('input, select, textarea, button[type=submit]');
                                for(var element of formElements){
                                    if(element.type === 'checkbox'){
                                        if(element.checked){
                                            formData[element.name] = element.value;
                                        }
                                    }else{
                                        formData[element.name] = element.value;
                                    }
                                }
                                formData['_source_url'] = _thisForm.getAttribute(_self.dataAttrs.sourceUrl);
                                formData['ajax'] = 1;
                                _self.removeAllErrors(this);
                                modalWindow.showInnerLoading();
                                aja()
                                    .method('post')
                                    .url(formBuilder.url)
                                    .type('json')
                                    .data(formData)
                                    .on('success', function (data) {
                                        modalWindow.removeInnerLoading();
                                        if(data.result){
                                            modalWindow.showIUnderstandConfirm(data.message);
                                            if(data.gtm){
                                                dataLayer.push({
                                                    'event': data.gtm.event,
                                                    'formType': data.gtm.formType
                                                });
                                            }
                                        }else{
                                            for (var key in data.errors) {
                                                var elementContainer = document.getElementById('c' + key);
                                                if(elementContainer){
                                                    addClass(elementContainer, _self.className.errorContainer);
                                                    elementContainer.append(_self.createErrorMessage(data.errors[key]));
                                                }
                                            }
                                            if(data.errors.length === 0){
                                                var submitError = document.createElement('div');
                                                submitError.className = _self.className.errorForm;
                                                submitError.innerHTML = data.message;
                                                _thisForm.append(submitError);
                                            }
                                        }
                                    })
                                    .go();
                            });
                        }
                    }
                })
                .go();
        });
    }

};