//=require ../../external/baguetteBox/js/baguetteBox.js
//=require ../../external/aja.js/aja.min.js
//=require ../../external/splide/js/splide-2.4.10.min.js
//=require ../../external/nouislider/js/nouislider.min.js
//=require front/functions.js
//=require front/modal-window.js
//=require front/form-list-of-url.js
//=require front/form-range-slider.js
//=require front/form-drop-down.js
//=require front/open-by-click.js
//=require front/menu.js
//=require front/form-builder.js
//=require front/gallery-show-specific-number-images.js
//=require front/button-go-up.js
//=require front/cookie-bar.js
//=require front/product-list-in-content-ask-for-demand.js
//=require front/packing-paragraphs.js
//=require general/form-click-protection.js

packingParagraphs.init();

baguetteBox.run('.js__baguettebox');

modalWindow.init();

formListOfUrl.init();

formRangeSlider.init();

formDropDown.init();

openByClick.init();

menu.initOpemClose();
menu.initDropDownClickTouch();

formBuilder.init();

cookieBar.init();

if(document.querySelector('.js__splide')) {
    new Splide('.js__splide', {
        'type': 'loop',
        'autoplay': true,
        'interval': 4000,
        'arrows': false
    }).mount();
}

if(document.querySelector('.js__bazar-detail-splide')) {
    new Splide('.js__bazar-detail-splide', {
        'type': 'loop',
        'autoplay': false,
        'interval': 4000,
        'arrows': true
    }).mount();
}

galleryShowSpecificNumberImages.init();

buttonGoUp.init();

window.onscroll = function() {
    topNavBarScroll();
};
var body = document.getElementsByTagName("body")[0];
var header = document.getElementById("topMenuScroll");
var logo = document.getElementById("topLogoScroll");
var sloganPhone = document.getElementById("topSloganPhoneScroll");
var sticky = header.offsetTop;
function topNavBarScroll() {
    if (window.pageYOffset > sticky) {
        body.classList.add("scroll-fix");
        header.classList.add("viva_top-menu--fixed","move-fixed");
        logo.classList.add("viva_top-logo--fixed", "move-fixed");
        sloganPhone.classList.add("viva_top-slogan-phone--fixed", "move-fixed");
    } else {
        body.classList.remove("scroll-fix");
        header.classList.remove("viva_top-menu--fixed","move-fixed");
        logo.classList.remove("viva_top-logo--fixed", "move-fixed");
        sloganPhone.classList.remove("viva_top-slogan-phone--fixed", "move-fixed");
    }
}

function shareOnfacebook(){
    var u = location.href;
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(u), 'FacebookShare', 'toolbar=0,status=0,width=626,height=436');
    return false;
}

function shareOnTweeter(t) {
    var u = location.href;
    window.open('http://twitter.com/share?url=' + encodeURIComponent(u) + '&text=' + encodeURIComponent(t), 'TwitterShare', 'toolbar=0,status=0,width=626,height=436');
    return false;
}

formClickProtection.init();

productListInContentAskForDemand.init();

const clockContainer = document.getElementById('jsAnalogClock');
if(clockContainer) {
    var clock = document.createElement('div');
    clock.id = 'clockContainer';
    clock.innerHTML = '<div id="hour"></div><div id="minute"></div><div id="second"></div>';
    insertAfter(clockContainer, clock);
    clockContainer.remove();
    //clock
    function myClock(){
        // lets make a date object
        d = new Date();

        hr = d.getHours();
        min = d.getMinutes();
        sec = d.getSeconds();

        // now convert these time values into the angle of rotation for hour min and second hands

        hr_rotation = 30 * hr + min / 2;

        // Ex: for hr = 12
        // hr_rotation = 30*12+ 0/2 = 360 deg
        // Ex: for hr = 6
        // hr_rotation = 30*6+ 0/2 = 180 deg

        // similar is for the minute and second hand

        min_rotation = 6 * min;
        sec_rotation = 6 * sec;

        // now lets rotate the hands with above obtained angle of rotation with the origin of rotation as botton

        hour.style.transform = `rotate(${hr_rotation}deg)`;
        minute.style.transform = `rotate(${min_rotation}deg)`;
        second.style.transform = `rotate(${sec_rotation}deg)`;
    }
    myClock();
    setInterval(myClock,1000);
}

const infoTextButtons = document.querySelectorAll('.js__info-text');
if(infoTextButtons) {

    for(let i = 0; i < infoTextButtons.length; i++) {

        infoTextButtons[i].addEventListener('click', function(){
            const
                contentID = this.getAttribute('data-container-id'),
                conteiner = document.getElementById(contentID);

            if(conteiner) {
                closeAllInfoTexts();
                conteiner.classList.add('open');
            }

        });
    }

}

function closeAllInfoTexts() {
    const list = document.querySelectorAll('.js__info-text-content');
    if(list) {

        for(let i = 0; i < list.length; i++) {

            list[i].classList.remove('open');

        }

    }
}

window.addEventListener('click', function(e){
    const element = e.target;
    if(element) {

        if(element.classList.contains('js__info-text') === false) {
            closeAllInfoTexts();
        }

    }
});