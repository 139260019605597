var openByClick = {

    elements: {
        items: '.js__open-on-click'
    },

    dataAttrs: {
        idOfElementForOpen: 'data-open-on-click-element-id'
    },

    className: {
        open: 'open-by-click'
    },

    init: function(){
        var _self = this;
        var listItems = document.querySelectorAll(this.elements.items);
        if(listItems.length > 0){
            for(var i = 0; i < listItems.length; i++){
                _self.initItem(listItems[i]);
            }
        }
    },

    initItem: function(el){
        var _self = this;
        el.addEventListener('click', function(e){
            e.preventDefault();
            _self.openClose(el);
        });
    },

    openClose: function(el){
        var elForOpen = document.getElementById(el.getAttribute(this.dataAttrs.idOfElementForOpen));
        if(elForOpen){
            if(hasClass(el, this.className.open)){
                removeClass(elForOpen, this.className.open);
                removeClass(el, this.className.open);
            }else{
                addClass(el, this.className.open);
                addClass(elForOpen, this.className.open);
            }
        }
    }

};